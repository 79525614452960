html,
body {
  font-family: "Marvel", sans-serif;
  background-color: #151515;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
li,
a {
  list-style: none;
  text-decoration: none;
  color: inherit;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  height: 45px;
  width: 250px;
  background-color: #ed161e;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  margin: 60px 0;
  outline: none;
  cursor: pointer;
}

/* HEADER ***********************************  HEADER  ********************/

.header {
  height: 50px;
  padding: 30px;
  width: 100%;
  background-color: #ed161e;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.header > div:first-child {
  flex: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .log-sign {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.header > div ul {
  display: flex;
  flex: 2;
  font-size: 35px;
  color: white;
  align-items: center;
}

.header > div ul li {
  margin: 0 30px 0 0;
}

.header img {
  height: 80px;
  margin-right: 30px;
  object-fit: contain;
}

/* LOADER ***********************************  LOADER  ********************/

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* CHARACTERS AND COMICS ***********************************  CHARACTERS AND COMICS  ********************/

/* CARD & COMIC CARD ***********  CARD & COMIC CARD  ********************/

.char-cont-butt,
.com-cont-butt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}

.char-cont-butt .button-div button:first-child,
.com-cont-butt .button-div button:first-child {
  margin-right: 50px;
}
.char-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.char-container > div p {
  margin-left: 30px;
  font-weight: 700;
}

.comics-container {
  color: white;
  width: 100%;
}
.char-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 500px;
  width: 300px;
  background-color: #ed161e;
  border-radius: 5px;
  padding: 30px;
  margin: 30px;
  color: white;
  -webkit-box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;
}

.char-card:hover,
.comic-card:hover {
  transform: scale(1.01);
}

.comic-card {
  display: flex;
  width: 80%;
  margin: 30px auto;
  background-color: #ed161e;
  border-radius: 5px;
  padding: 30px;
  color: white;
  -webkit-box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;
}

.char-card span,
.comic-card span {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.char-card img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 30px;
}

.comic-card img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin-right: 30px;
  border-radius: 5px;
}

.heart-icon {
  position: absolute;
  right: 50px;
  top: 50px;
  font-size: 24px;
  z-index: 2;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.cross-icon-comic-card {
  position: absolute;
  right: 150px;
  top: 20px;
  font-size: 24px;
  z-index: 2;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.heart-icon-comics {
  position: absolute;
  right: 220px;
  top: 30px;
  font-size: 30px;
  z-index: 2;
  padding: 10px;
  color: white;
  cursor: pointer;
}

/* SEARCH ***********************************  SEARCH  ********************/

.search form {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search input[type="text"] {
  height: 50px;
  width: 400px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-left: 15px;
  font-family: "Marvel", sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.search input[type="submit"] {
  font-family: "Marvel", sans-serif;
  font-size: 20px;
  font-weight: 700;
  outline: none;
  border: none;
  box-sizing: border-box;
  margin-left: 30px;
  border-radius: 5px;
  height: 50px;
  width: 100px;
}

.search input::placeholder {
  font-family: "Marvel", sans-serif;
  font-size: 24px;
  font-weight: 700;
}

/* COMICS PER CHARACTER ***********************************  COMICS PER CHARACTER ********************/

.comic-per-character-container {
  color: white;
  margin-top: 150px;
}

/* LOGIN AND LOGOUT ***********************************  LOGIN AND LOGOUT ********************/

.log-in-container {
  width: 40%;
  margin: 150px auto 0;
  background-color: #ed161e;
  padding: 40px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 26px -15px rgba(255, 255, 255, 1);
}

.log-in-container form div {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.log-in-container form span {
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.log-in-container input {
  border: none;
  height: 45px;
  border-radius: 5px;
  outline: none;
  padding-left: 15px;
  font-family: "Marvel", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.log-in-container input[type="submit"] {
  width: 100%;
  box-sizing: border-box;
  margin-top: 50px;
  color: white;
  background-color: #151515;
}

.log-sign {
  color: white;
  font-size: 24px;
}

.log-sign span {
  cursor: pointer;
}

.favorites {
  margin-top: 150px;
}

.favorites p {
  margin-left: 30px;
  font-weight: 700;
}
